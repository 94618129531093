import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import icon from '../../public/images/icon1.png';
import './GoogleLogin.css';

function SignInWithGoogle() {
  // 允許的 Email 清單
  const arryEmail = [
    // 若涵
    'vanessa042521@gmail.com',

    // 核心夥伴
    'fly8636@gmail.com', 'besweet851002@gmail.com', 'jacky@cosmofii.com', 'taiwan19951221@gmail.com',

    // 元老
    'qqjameqq1@gmail.com', 'littleflyer2015@gmail.com', 'zoe76102469@gmail.com', 'gili7lucky@gmail.com', 'service@cosmofii.com',

    // 雅文帳號
    'fionachiu@chfn.org.tw', 'aliceyu@chfn.org.tw', 'wendylai@chfn.org.tw', 'daisylee@chfn.org.tw', 'libbylong@chfn.org.tw', 'judyhuang@chfn.org.tw', 'tinachung@chfn.org.tw', 'hayleylin@chfn.org.tw', 'kikiting@chfn.org.tw', 'stefaniefang@chfn.org.tw', 'yahsincheng@chfn.org.tw', 'yutungchang@chfn.org.tw', 'candychang@chfn.org.tw', 'ziyalin@chfn.org.tw', 'chianyowkwek@chfn.org.tw', 'helencheng@chfn.org.tw', 'rebeccacheung@chfn.org.tw', 'afrayu@chfn.org.tw', 'lilyhu@chfn.org.tw', 'jiajiawu@chfn.org.tw', 'lucindalin@chfn.org.tw', 'teresayang@chfn.org.tw', 'abigailhsu@chfn.org.tw', 'antingcheng@chfn.org.tw', 'guanyawang@chfn.org.tw', 'danyuchung@chfn.org.tw', 'minhsinlo@chfn.org.tw', 'roseyu@chfn.org.tw', 'yvonnechen@chfn.org.tw', 'weiyuchen@chfn.org.tw', 'lynnwang@chfn.org.tw', 'carollai@chfn.org.tw', 'lesleylin@chfn.org.tw', 'yuchenhung@chfn.org.tw', 'joleenchu@chfn.org.tw',
  ];

  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  // 初始化 Firebase 應用
  const firebaseConfig = {
    apiKey: "AIzaSyDad4JckQLNMALW3RpKnTq6X3KuPP90Ej0",
    authDomain: "polavoice-test.firebaseapp.com",
    projectId: "polavoice-test",
    storageBucket: "polavoice-test.appspot.com",
    messagingSenderId: "163776593379",
    appId: "1:163776593379:web:fc91cac693491786abb4d6",
    measurementId: "G-GX4XSS66XR"
  };

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const analytics = getAnalytics(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(analytics, user.uid);
        logEvent(analytics, 'login', {
          method: 'Google'
        });
        navigate('/');
      } else {
        logEvent(analytics, 'logout');
        setUserId(analytics, null);
        navigate('/Login');
      }
    });

    return () => unsubscribe();
  }, [auth, analytics, navigate]);

  // Email 登入處理
  const handleEmailSignIn = () => {
    if (arryEmail.includes(email.trim().toLowerCase())) {
      // console.log("登入成功");
      navigate('/?education=1');
    } else {
      setError('此 Email 無權登入');
    }
  };

  // Google 登入處理
  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const userEmail = result.user.email; // 取得使用者的 Email

        if (arryEmail.includes(userEmail.trim().toLowerCase())) {
          // 允許登入
          window.dataLayer.push({
            event: 'login',
            userId: result.user.uid,
            userEmail: userEmail,
          });
          navigate('/');
        } else {
          // 拒絕登入，登出使用者
          setError('此 Email 無權登入');
          alert('此 Email 無權登入');
          auth.signOut(); // 強制登出
        }
      }).catch((error) => {
        console.error(error);
      });
  };


  return (
    <div className="container mx-auto">
      <Helmet>
        <title>登入</title>
      </Helmet>
      <div className="center">
        <div className="auth mb-4">
          <p className="auth-text">帳號登入</p>
        </div>
        <button className="google-login-button" onClick={handleGoogleSignIn}>
          <img src={icon} alt="Google Icon" className="google-icon relative inline-block" />
          <span className="google-login-btn-text relative top-0.5">使用 Google 登入</span>
        </button>

        <div className="relative my-2 py-4 w-full">
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="w-full border-b border-gray-500"></div>
          </div>
          <div className="relative flex justify-center">
            <span className="bg-white px-4 text-sm text-gray-500">或</span>
          </div>
        </div>

        {error && <p className="text-red-500 text-sm">{error}</p>}

        <div className="relative my-2">
          <input
            type="text"
            className="relative px-2 h-10 border border-gray-500 rounded-lg w-full"
            placeholder="請輸入 Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <button className="google-login-button" onClick={handleEmailSignIn}>
          <span className="top-0.5">使用 Email 登入</span>
        </button>
      </div>
    </div>
  );
}

export default SignInWithGoogle;
